import React from 'react';
import './PrivacyPolicy.css'; // Make sure this file exists and contains the necessary styles

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <h2>Privacy Policy for Cwav Audio Player App</h2>
      <p><strong>1. Information Collection</strong></p>
      <p>Cwav does not collect any personal data or information from its users.</p>
      <p><strong>2. Audio Files</strong></p>
      <p>All audio files played on Cwav are stored locally on your device. Cwav does not access, store, or transmit your audio files.</p>
      <p><strong>3. Permissions</strong></p>
      <p>Cwav requires permission to access your device’s storage solely for the purpose of playing audio files.</p>
      <p><strong>4. Third-Party Services</strong></p>
      <p>Cwav does not use any third-party services that could collect your information.</p>
      <p><strong>5. Changes to This Privacy Policy</strong></p>
      <p>Any changes to this privacy policy will be updated within the app. Users are encouraged to review the policy periodically.</p>
      <p><strong>6. Contact Us</strong></p>
      <p>If you have any questions about this privacy policy, please contact us at <a href="mailto:cwav.app@gmail.com">cwav.app@gmail.com</a></p>
    </div>
  );
}

export default PrivacyPolicy;