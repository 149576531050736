import React from 'react';
import './HomeScreen.css'; // Create this CSS file for styling
import logo from '../assets/logo.png'; // Adjust the path based on your assets directory
import appleLogo from '../assets/apple.png';
import androidLogo from '../assets/android.png';
import windowsLogo from '../assets/windows.png';
import macosLogo from '../assets/macos.png';

function HomeScreen() {
  return (
    <div className="home-screen">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
        <h1>Cwav</h1>
        <p>The offline audio player, for offliners</p>
      </header>
      <div className="platforms">
        <img src={appleLogo} className="platform-logo" alt="Apple" />
        <img src={androidLogo} className="platform-logo" alt="Android" />
        <img src={windowsLogo} className="platform-logo" alt="Windows" />
        <img src={macosLogo} className="platform-logo" alt="Mac OS" />
      </div>
      <div className="description">
        <p>Cwav is a simple to use audio player for those who still listen to mp3/wav files.</p>
      </div>
      <div className="how-it-works">
        <h2>How it works:</h2>
        <ol>
          <li>Download the app</li>
          <li>Go to settings screen, select Add directory (big grey button)</li>
          <li>Go to your files, find Cwav</li>
          <li>Within this folder you will see another folder called “Music”</li>
          <li>Dump your mp3/wav files (folders also work)</li>
          <li>Refresh the app, and they will appear under the library tab</li>
          <li>Enjoy listening</li>
        </ol>
      </div>
      <footer className="footer">
        <p>Any questions, please email: cwav.app@gmail.com</p>
      </footer>
    </div>
  );
}

export default HomeScreen;